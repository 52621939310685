<template>
  <div class="main" style="top: 0; width: 100%">
    <!-- <van-loading color="#1989fa"></van-loading> -->
    <!-- <div class="main"> -->
    <div class="header">
      <div class="left">
        <div class="tab-list">
          <div class="tab-item">客资分析</div>
        </div>
      </div>
      <div class="right">
        <div class="sort-list">
          <div class="sort-item" @click="eventHandle('showSort', !showSort)">
            {{ dateDesc }}<img src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/icon/icon_workcenter_nextmore.png" />
          </div>
          <view class="sortSub-list" v-if="showSort">
            <view class="sortSub-item" v-for="(item, index) in dateTypeList" :key="index" @click="select(item)"
              ><div>
                {{ item.dateDesc }}
              </div></view
            >
          </view>
        </div>
      </div>
    </div>
    <div class="statistics">
      <div class="item">
        <div class="title">新增客资数</div>
        <div class="text">毛客资成本 {{ unitConvert(custStasDto.custCost, false) }}</div>
        <div class="count blue">
          {{ custStasDto.newCustNumr }}
          <img v-if="custStasDto.custCost > 0" :src="countImg" />
        </div>
      </div>
      <img class="item item-img" :src="itemImg" />
      <div class="item">
        <div class="title">有效客资数</div>
        <div class="text">客资成本 {{ unitConvert(custStasDto.effeCustCost) }}</div>
        <div class="count">{{ custStasDto.custNumr }}</div>
      </div>
      <!-- <img class="item item-img" :src="itemImg" /> -->
      <!-- <div class="item">
        <div class="title">无效客资数</div>
        <div class="text">无效率 {{ custStasDto.invdRate }}%</div>
        <div class="count">{{ custStasDto.newNetNumr }}</div>
      </div> -->
      <img class="item item-img" :src="itemImg" />
      <!-- <div class="item">
        <div class="title">流失客资数</div>
        <div class="text">流失率 1.4%</div>
        <div class="count">1</div>
      </div>
      <img class="item item-img" :src="itemImg" /> -->
      <div class="item">
        <div class="title">到店客资数</div>
        <div class="text">到店率 {{ custStasDto.toMertRate }}%</div>
        <div class="count blue">
          {{ custStasDto.toMertCustNumr }}
          <img v-if="custStasDto.toMertCustNumr > 0" :src="countImg" />
        </div>
      </div>
      <img class="item item-img" :src="itemImg" />
      <div class="item">
        <div class="title">成交数</div>
        <div class="text">新客成交率 {{ custStasDto.dealRate }}%</div>
        <div class="count blue">
          {{ custStasDto.dealCustNumr }}
          <img v-if="custStasDto.dealCustNumr > 0" :src="countImg" />
        </div>
      </div>
      <img class="item item-img" :src="itemImg" />
      <div class="item">
        <div class="title">订单金额</div>
        <div class="text">客单价 {{ unitConvert(custStasDto.custPrice, false) }}</div>
        <div class="count">
          {{ unitConvert(custStasDto.orderAmot, true).num }} <text>{{ unitConvert(custStasDto.orderAmot, true).unit }}</text>
        </div>
      </div>
      <img class="item item-img" :src="itemImg" />
      <div class="item">
        <div class="title">已收金额</div>
        <div class="text">已收意向金 {{ unitConvert(custStasDto.alryRecdIntn, false) }}</div>
        <div class="count">
          {{ unitConvert(custStasDto.alryRecdAmot, true).num }} <text>{{ unitConvert(custStasDto.alryRecdAmot, true).unit }}</text>
        </div>
      </div>
      <!-- <img class="item item-img" :src="itemImg" /> -->
      <!-- <div class="item">
        <div class="title">退单数</div>
        <div class="text">退单率 0.0%</div>
        <div class="count">0</div>
      </div>
      <img class="item item-img" :src="itemImg" /> -->
      <!-- <div class="item">
        <div class="title">总投入</div>
        <div class="text">投产比 1:9</div>
        <div class="count">
          {{ unitConvert(custStasDto.totalInvt, true).num }} <text>{{ unitConvert(custStasDto.totalInvt, true).unit }}</text>
        </div>
      </div> -->
    </div>
    <div class="ucharts-list" id="ucharts-list" :style="{ 'max-width': `${boxWidth}px` }">
      <div class="ucharts-item" :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }">
        <div class="Detailed-analysis" v-if="custChalStasDtoDetail.chalName">
          <div class="title">
            <div class="icon"></div>
            详细分析
          </div>
          <div>{{ `${custChalStasDtoDetail.chalName}:${custChalStasDtoDetail.chalNewCustNumr}(${custChalStasDtoDetail.chalNewCustRate}%)` }}</div>
          <div>{{ `成交数:${custChalStasDtoDetail.chalSucsCustNumr}(${custChalStasDtoDetail.chalSucsCustRate}%)` }}</div>
        </div>
        <div id="vertical" :style="{ height: `${contentHeight}px`, width: `${contentWidth}px` }"></div>
      </div>
      <div class="ucharts-item" :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }">
        <div id="axis2" :style="{ height: `${contentHeight}px`, width: `${contentWidth}px` }"></div>
      </div>
      <div class="ucharts-item" :style="{ width: `${boxWidth2}px`, height: `${contentHeight}px` }">
        <div id="axis1" :style="{ height: `${contentHeight}px`, width: `${boxWidth2}px` }"></div>
      </div>
      <!-- <div class="ucharts-item" :style="{ width: `${contentWidth}px`, height: `${contentHeight}px` }">
        <div id="axis3" :style="{ height: `${contentHeight}px`, width: `${contentWidth}px` }"></div>
      </div> -->
    </div>

    <div style="margin-bottom: 20px"></div>
  </div>
</template>

<script>
import { getCustDataCenterStas, getDateTypeList } from '@/api/index';
import BigNumber from 'bignumber.js';

//计算,防止精度问题
function mathCompute(number1, number2, express) {
  let num = 0;

  if (number1 === '' || number1 === null || number1 === undefined || number2 === '' || number2 === null || number2 === undefined) return num;

  switch (express) {
    case '+':
      num = BigNumber(Number(number1)).plus(Number(number2));
      break;
    case '-':
      num = BigNumber(Number(number1)).minus(Number(number2));
      break;
    case '*':
      num = BigNumber(Number(number1)).multipliedBy(Number(number2));
      break;
    case '/':
      num = BigNumber(Number(number1)).dividedBy(Number(number2));
      break;
  }

  return Number(num.toString());
}

// 引入 ECharts 主模块
const echarts = require('echarts/lib/echarts');
// 引入柱状图
require('echarts/lib/chart/bar');
require('echarts/lib/chart/pie');
require('echarts/lib/chart/line');
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require('echarts/lib/component/legend');

import { Toast, Loading, Dialog } from 'vant';
import 'vant/lib/index.css';

const loginToken = localStorage.getItem('loginToken');
const disturbCustUserCode = localStorage.getItem('disturbCustUserCode');

export default {
  computed: {
    countImg() {
      return 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/icon/icon_tongji_updetail.png';
    },
    itemImg() {
      return 'https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/icon/icon_tongji_xiegang.png';
    },
    dateDesc() {
      let dateDesc = '';
      if (this.dateTypeList && this.dateTypeList.length) {
        this.dateTypeList.forEach((item) => {
          if (this.params.dateType == item.dateType) {
            dateDesc = item.dateDesc;
          }
        });
      }
      if (!dateDesc) dateDesc = '今年';
      return dateDesc;
    },
  },
  data() {
    return {
      params: {
        date: '',
        dateFlag: '',
        dateType: '',
        mertCode: '',
        disturbtoken: loginToken,
        disturbCustUserCode: disturbCustUserCode,
        disturbequipmenttype: '',
        disturbequipmentmeid: '',
      },
      dateTypeList: [],
      custStasDto: {
        newCustNumr: 0, //新增客资数
        custCost: 0, //毛客资成本
        custNumr: 0, //有效客资数
        effeCustCost: 0, //有效客资成本
        newNetNumr: 0, //无效客资数
        invdRate: 0, //无效率
        toMertCustNumr: 0, //到店客资数
        toMertRate: 0, //到店率
        dealCustNumr: 0, //成交数
        dealRate: 0, //成交率
        custPrice: 0, //客单价
        orderAmot: 0, //订单金额
        alryRecdAmot: 0, //已收金额
        alryRecdIntn: 0, //已收意向金
        totalInvt: 0, //总投入
      },
      custChalStasDtoDetail: {
        chalName: '',
        chalNewCustNumr: '',
        chalNewCustRate: '',
        chalSucsCustNumr: '',
        chalSucsCustRate: '',
      },
      boxWidth: '',
      boxWidth2: '',
      contentHeight: '',
      contentWidth: '',
      showSort: false,
      envType: '',
    };
  },
  mounted() {
    const _self = this;
    getDateTypeList().then((res) => {
      this.dateTypeList = res.data.result;
    });

    uni.getEnv(function (res) {
      for (const key in res) {
        _self.envType = key;
      }
    });

    if (this.envType == 'plus') {
      const urlParams = (function () {
        const urlParams = new Object();
        const url = window.location.href;
        if (url.indexOf('?') > -1) {
          let str = url.split('?')[1];
          if (str.indexOf('#') > -1) str = str.split('#')[0];
          const strs = str.split('&');
          for (let i = 0; i < strs.length; i++) {
            urlParams[strs[i].split('=')[0].toLowerCase()] = strs[i].split('=')[1];
          }
        }
        return urlParams;
      })();
      if (urlParams.logintoken) this.params.disturbtoken = urlParams.logintoken;
      if (urlParams.disturbequipmenttype) this.params.disturbEquipMentType = urlParams.disturbequipmenttype;
      if (urlParams.disturbequipmentmeid) this.params.disturbEquipMentMeid = urlParams.disturbequipmentmeid;
    }

    Toast.loading({
      duration: 0,
      forbidClick: true,
      message: '加载中...',
    });

    setTimeout(() => {
      this.boxWidth = document.getElementById('ucharts-list').offsetWidth;
      this.boxWidth2 = document.getElementById('ucharts-list').offsetWidth - 5;
      this.contentHeight = document.getElementById('ucharts-list').offsetHeight / 2 - 5;
      this.contentWidth = document.getElementById('ucharts-list').offsetWidth / 2 - 5;
      this._getCustDataCenterStas();
    }, 1000);
  },
  methods: {
    select(item) {
      this.params.dateType = item.dateType;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...',
      });

      this._getCustDataCenterStas();
    },
    eventHandle(name, data) {
      this[name] = data;
    },
    /*
     * 客资统计
     */
    async _getCustDataCenterStas() {
      await getCustDataCenterStas(this.params).then((res) => {
        const data = res.data.result;
        this.$nextTick(() => {
          Toast.clear();
          this.custStasDto = data.custStasDto;

          this.getVertical(data.custChalStasDtoList);
          this.getAxis1(data.custTotalNumrDto);
          this.getAxis2(data.custTotalBillInfoDto);
          // this.getAxis3(data.custTotalInOutPutDto);

          this.showSort = false;
        });
      });
    },
    getVertical(custChalStasDtoList) {
      const myChart = echarts.init(document.getElementById('vertical'));

      let legendData = [],
        seriesData = [],
        listStyle = ['#3460A6', '#6999D1', '#A0D3ED', '#83B5CE', '#8DDFCE', '#7a95e2'];

      if (custChalStasDtoList && custChalStasDtoList.length) {
        legendData = custChalStasDtoList.map((item) => {
          return item.chalName;
        });

        seriesData = custChalStasDtoList.map((item, index) => {
          return {
            name: item.chalName,
            value: item.chalNewCustNumr,
            chalName: item.chalName,
            chalNewCustNumr: item.chalNewCustNumr,
            chalNewCustRate: item.chalNewCustRate,
            chalSucsCustNumr: item.chalSucsCustNumr,
            chalSucsCustRate: item.chalSucsCustRate,
            itemStyle: {
              color: listStyle[index],
              borderWidth: 2,
              borderColor: '#fff',
            },
          };
        });
      }

      const option = {
        title: {
          text: '客资来源分布',
          textStyle: {
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 10,
        //   top: 30,
        //   data: legendData,
        // },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],

            label: {
              position: 'inner',
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|({d}%)}  ',
              backgroundColor: '#fff',
              borderColor: '#fff',
              borderWidth: 0,
              borderRadius: 4,
              fontSize: 10,
              shadowBlur: 3,
              shadowOffsetX: 2,
              shadowOffsetY: 2,
              shadowColor: '#fff',
              padding: [0, 0],
              rich: {
                a: {
                  color: '#c8c8c8',
                  height: 0,
                  lineHeight: 0,
                  align: 'center',
                  fontSize: 10,
                },
                // abg: {
                // backgroundColor: '#333',
                // width: '100%',
                // align: 'right',
                // height: 22,
                // borderRadius: [4, 4, 0, 0]
                // },
                hr: {
                  height: 0,
                  lineHeight: 0,
                  fontSize: 10,
                },
                b: {
                  fontSize: 15,
                  height: 0,
                  lineHeight: 0,
                },
                per: {
                  color: '#323232',
                  backgroundColor: '#fff',
                  padding: [2, 4],
                  borderRadius: 2,
                  fontSize: 10,
                },
              },
            },
            data: seriesData,
          },
        ],
      };

      myChart.setOption(option);

      // myChart.on('click', custStasClick);

      // function custStasClick(param) {
      //   _self.custChalStasDtoDetail.name = param.name;
      //   _self.custChalStasDtoDetail.value = param.value;
      //   for (var key in _self.custChalStasDtoDetail) {
      //     _self.custChalStasDtoDetail[key] = seriesData[param.dataIndex][key];
      //   }
      // }
    },
    getAxis1(custTotalNumrDto) {
      const myChart = echarts.init(document.getElementById('axis1'));

      const legendData = [
          `新增客资数${custTotalNumrDto.newTotalCustNumr}`,
          `有效客资数${custTotalNumrDto.effeCustTotalNumr}`,
          `成交总数${custTotalNumrDto.sucsTotalNumr}`,
          // `无效客资数${custTotalNumrDto.invdCustTotalNumr}`,
        ],
        xAxisData = [],
        seriesData1 = [],
        seriesData2 = [],
        seriesData3 = [],
        seriesData4 = [];

      if (custTotalNumrDto.custNumrDtoList && custTotalNumrDto.custNumrDtoList.length) {
        custTotalNumrDto.custNumrDtoList.forEach((item) => {
          xAxisData.push(item.date);
          seriesData1.push(item.newCustNumr);
          seriesData2.push(item.effeCustNumr);
          seriesData3.push(item.sucsNumr);
          seriesData4.push(item.invdCustNumr);
        });
      }

      const option = {
        title: {
          text: '客资质量分析',
          textStyle: {
            fontSize: 15,
          },
        },
        color: ['#fad764', '#257dfc', '#10bfc4'],
        // color: ['#fad764', '#257dfc', '#10bfc4', '#c8c8c8'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: legendData,
          top: '6%',
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '15%',
          bottom: '0%',
          containLabel: true,
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: legendData[0],
            type: 'line',
            barGap: 0,
            // label: labelOption,
            data: seriesData1,
            smooth: true,
          },
          {
            name: legendData[1],
            type: 'line',
            // label: labelOption,
            data: seriesData2,
            smooth: true,
          },
          {
            name: legendData[2],
            type: 'line',
            // label: labelOption,
            data: seriesData3,
            smooth: true,
          },
          // {
          //   name: legendData[3],
          //   type: 'line',
          //   // label: labelOption,
          //   data: seriesData4,
          //   smooth: true,
          // },
        ],
      };

      myChart.setOption(option, true);
    },
    getAxis2(custTotalBillInfoDto) {
      const myChart = echarts.init(document.getElementById('axis2'));

      const legendData = [
          `成交数${custTotalBillInfoDto.sucsTotalNumr}`,
          `订单金额${custTotalBillInfoDto.sumTotalAmot}`,
          `客单价${custTotalBillInfoDto.custTotalPrice}`,
          // `订单金额${this.unitConvert(custTotalBillInfoDto.sumTotalAmot, false)}`,
          // `客单价${this.unitConvert(custTotalBillInfoDto.custTotalPrice, false)}`,
        ],
        xAxisData = [],
        seriesData1 = [],
        seriesData2 = [],
        seriesData3 = [];

      if (custTotalBillInfoDto.custBillInfoDtoList && custTotalBillInfoDto.custBillInfoDtoList.length) {
        custTotalBillInfoDto.custBillInfoDtoList.forEach((item) => {
          xAxisData.push(item.date);
          seriesData1.push(item.sucsNumr);
          seriesData2.push(item.sumAmot);
          seriesData3.push(item.custPrice);
        });
      }

      const option = {
        title: {
          text: '成交数据分析',
          textStyle: {
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '20%',
          bottom: '0%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: legendData,
          top: '6%',
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '单',
            // min: 10000,
            // max: 25,
            // interval: 5,
            axisLabel: {
              formatter: '{value}',
            },
          },
          {
            type: 'value',
            name: '金额',
            // min: 5,
            // max: 35,
            // interval: 50,
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: legendData[0],
            type: 'line',
            yAxisIndex: 0,
            data: seriesData1,
            itemStyle: {
              color: '#F6DC7D',
            },
          },
          {
            name: legendData[1],
            type: 'bar',
            yAxisIndex: 1,
            data: seriesData2,
          },
          {
            name: legendData[2],
            type: 'bar',
            yAxisIndex: 1,
            data: seriesData3,
          },
        ],
      };

      myChart.setOption(option, true);
    },
    getAxis3(custTotalInOutPutDto) {
      const myChart = echarts.init(document.getElementById('axis3'));

      const legendData = [
          // `总投入${this.unitConvert(custTotalInOutPutDto.advgTotalMony, false)}`,
          // `订单金额${this.unitConvert(custTotalInOutPutDto.sumTotalAmot, false)}`,
          `总投入${custTotalInOutPutDto.advgTotalMony}`,
          `订单金额${custTotalInOutPutDto.sumTotalAmot}`,
        ],
        xAxisData = [],
        seriesData1 = [],
        seriesData2 = [];

      if (custTotalInOutPutDto.custInOutPutDtoList && custTotalInOutPutDto.custInOutPutDtoList.length) {
        custTotalInOutPutDto.custInOutPutDtoList.forEach((item) => {
          xAxisData.push(item.date);
          seriesData1.push(item.advgMony);
          seriesData2.push(item.sumAmot);
        });
      }

      const option = {
        title: {
          text: '投入产出分析',
          textStyle: {
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '20%',
          bottom: '0%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: legendData,
          top: '6%',
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '金额',
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: legendData[0],
            type: 'line',
            data: seriesData1,
          },
          {
            name: legendData[1],
            type: 'bar',
            data: seriesData2,
          },
        ],
      };

      myChart.setOption(option);
    },
    unitConvert(num, object) {
      const moneyUnits = ['元', '万', '亿元', '万亿'];
      const dividend = 10000;
      let curentNum = mathCompute(num, 0, '+');

      //转换数字
      let curentUnit = moneyUnits[0];

      //转换单位
      for (let i = 0; i < 4; i++) {
        curentUnit = moneyUnits[i];
        if (this.strNumSize(curentNum) < 5) {
          break;
        }
        curentNum = mathCompute(curentNum, dividend, '/');
      }

      const m = { num: 0, unit: '' };

      if (mathCompute(curentNum, dividend, '-') > 0) {
        m.num = curentNum;
        m.unit = curentUnit;
      } else {
        m.num = curentNum.toFixed(2);
        m.unit = curentUnit;
      }

      if (object) {
        return m;
      } else {
        return `${m.num}${m.unit}`;
      }
    },
    strNumSize(tempNum) {
      const stringNum = tempNum.toString();

      const index = stringNum.indexOf('.');
      let newNum = stringNum;
      if (index != -1) {
        newNum = stringNum.substring(0, index);
      }
      return newNum.length;
    },
  },
};
</script>

<style lang="scss" scoped>
html {
  position: fixed;
  left: 0;
  width: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: fixed;
  .header {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    z-index: 10;
    .left {
      .tab-list {
        .tab-item {
          font-size: 16px;
          color: #3460a6;
          font-weight: bold;
          margin: 15px 15px 7.5px 15px;
          padding-bottom: 7.5px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 15px;
            height: 2.5px;
            background: #3460a6;
            box-shadow: 0px 5px 8px 0px rgba(52, 96, 166, 0.38);
            border-radius: 1px;
          }
        }
      }
    }
    .right {
      .sort-list {
        width: 84px;
        height: 100%;
        position: relative;
        .sort-item {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 10px;
            height: 5px;
            margin-left: 5px;
          }
        }
        .sortSub-list {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          background-color: #f2f1f5;
          box-shadow: 0px 8px 3.5px 0px rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 10px 10px;
          .sortSub-item {
            height: 33px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
              margin: 0 8.5px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              border-bottom: 1px solid #dcdcdc;
            }
            &:last-child {
              div {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  .statistics {
    min-height: 77.5px;
    height: 77.5px;
    margin: 0 15px 10px;
    background-color: #fff;
    box-shadow: 0px 2.5px 5px 0px rgba(150, 150, 150, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 33px 0 20px;
    .item {
      &.item-img {
        width: 4.5px;
        height: 18.5px;
      }
      .title {
        font-size: 10px;
        color: #323232;
      }
      .text {
        font-size: 9px;
        color: #969696;
        margin: 4.5px 0 10px;
      }
      .count {
        font-size: 21px;
        color: #008aff;
        &.green {
          color: #8ddfce;
        }
        &.blue {
          color: #3460a6;
        }
        text {
          font-size: 12px;
        }
        img {
          width: 7px;
          height: 7.5px;
          margin-left: 5px;
        }
      }
    }
  }
  .ucharts-list {
    flex: 1;
    // height: 100%;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    margin: 0 10px 0 15px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .ucharts-item {
      background-color: #fff;
      margin: 0 5px 5px 0;
      border-radius: 10px;
      box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 0.2);
      position: relative;
      .Detailed-analysis {
        position: absolute;
        top: 35px;
        right: 35px;
        width: 138.5px;
        height: 95px;
        box-shadow: 0px 0px 5px 0px rgba(150, 150, 150, 0.2);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 7.5px 0 7.5px 10px;
        font-size: 11px;
        color: #323232;
        .title {
          display: flex;
          align-items: center;
          color: #3460a6;
          font-weight: bold;
          .icon {
            width: 2.5px;
            height: 10px;
            background-color: #3460a6;
            border-radius: 1.5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>

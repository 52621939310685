import Axios from 'axios';

// Axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/uat' : sessionStorage['zuul_url'];
// Axios.defaults.headers = {
//   Authorization: `Bearer ${sessionStorage['access_token']}`
// };
let loginToken: any = '',
  disturbEquipMentType: any = '',
  disturbEquipMentMeid: any = '';
if (localStorage.getItem('loginToken')) {
  loginToken = localStorage.getItem('loginToken');
}
if (localStorage.getItem('disturbEquipMentType')) {
  disturbEquipMentType = localStorage.getItem('disturbEquipMentType');
}
if (localStorage.getItem('disturbEquipMentMeid')) {
  disturbEquipMentMeid = localStorage.getItem('disturbEquipMentMeid');
}
Axios.defaults.data = {
  disturbtoken: loginToken,
  disturbEquipMentType: disturbEquipMentType,
  disturbEquipMentMeid: disturbEquipMentMeid
};

if (process.env.NODE_ENV === 'development') {
  Axios.defaults.baseURL = '/lc';
  // Axios.defaults.baseURL = '/api'
  // Axios.defaults.baseURL = '/uat';
  // Axios.defaults.baseURL = '/officail';
} else {
  // Axios.defaults.baseURL = 'http://192.168.0.104:8889';
  Axios.defaults.baseURL = '/api/fd-hunxiu-service';
  // Axios.defaults.baseURL = 'https://zapi.xinnuoshijia.com/by-fd-hunxiu-service';
  // Axios.defaults.baseURL = 'https://zapi.xinnuoshijia.com/fd-hunxiu-service';
  // Axios.defaults.baseURL = 'https://api.51afd.com/fd-hunxiu-service-by';
}

interface BaseRes {
  code: string;
  message?: string;
}

export const loginWithAcctAndPass = async (params: any) => {
  return await Axios.post<any>('/sysmUser/loginWithAcctAndPass', null, { params });
};

export const getCustDataCenterStas = async (params: any) => {
  return await Axios.get<any>('/dataCenter/getCustDataCenterStas', { params });
};

export const getDateTypeList = async () => {
  return await Axios.get<any>('/custStas/getDateTypeList');
};

export const pcWxAuthLogin = async (params: any) => {
  return await Axios.post<any>('/wxPc/pcWxAuthLogin', null, { params });
};

export const getVerCode = async (params: any) => {
  return await Axios.get<any>('/wxPc/getVerCode', { params });
};

export const pcUserTeleAuthLogin = async (params: any) => {
  return await Axios.post<any>('/wxPc/pcUserTeleAuthLogin', null, { params });
};

export const getTicketRequest = async (params: GetTicketRequestParams) => {
  return await Axios.post<GetTicketRes>('/fd-bill/ticketController/getTicket', null, { params });
};

export interface TicketItem {
  aProductTypeName: string;
  bApplyCustomerName: string;
  bApplyTel: string;
  bApprovalMount: string;
  bBillCharge: string;
  bBillDiscount: string;
  biBillFee: string;
  biBillId: number;
  biBillLoan: string;
  biBillMerchantRenal: string;
  biBillMonth: string;
  biBillOweFee: string;
  biBillPayFee: string;
  biBillRate: string;
  biCyclyName: string;
  biPayStatus: string;
  dimValue: string;
  flag: string;
  mMerchantName: string;
  mOrgName: string;
  type: string;
}

export type GetTicketRes = {
  result: {
    records: TicketItem[];
    current: number;
    pages: number;
    size: number;
    total: number;
  };
} & BaseRes;

export interface GetTicketRequestParams {
  page: number;
  size: number;
  tel: string | undefined;
  name: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  status: string | undefined;
  type: string | undefined;
  code: string | undefined;
  aProductType: string | undefined;
  mOrgCode: string | undefined;
  mMerchantCode: string | undefined;
  access_token: string;
}

export const updateTicketRequest = async (params: UpdateTicketRequestParams) => {
  return await Axios.post<UpdateTicketRequestRes>('/fd-bill/ticketController/updateTicket', params);
};

export type UpdateTicketRequestParams = TicketItem['biBillId'][];

export type UpdateTicketRequestRes = {
  message: string;
  code: string;
} & BaseRes;

export const exportTicketRequest = async (params: ExportTicketRequestParams) => {
  return await Axios.get<exportTicketRequestRes>('/fd-bill/ticketController/exportTicket', { params, responseType: 'blob' });
};

export type ExportTicketRequestParams = GetTicketRequestParams;

export type exportTicketRequestRes = Blob;

export const getSearchRequest = async () => {
  return await Axios.post<GetSearchRequesRes>('/fd-bill/ticketController/getSearch');
};

export type GetSearchRequesRes = {
  result: {
    org: OrgItem[];
    productList: ProductItem[];
  };
} & BaseRes;

export interface OrgItem {
  mOrgCode: string;
  mOrgName: string;
}
export interface ProductItem {
  pId: string;
  pCode: string;
  pName: string;
  pDesc: string;
  pStatus: string;
  pRegionCode: string;
  createUser: string;
  createTime: string;
  modifyUser: string;
  modifyTime: string;
  pDataCongigure: string;
  graeNumr: string;
  minAge: string;
  businessType: string;
}

export const getMechantRequest = async (params: GetMechantRequestParams) => {
  return await Axios.post<GetMechantRequestRes>('/fd-bill/ticketController/getMechant', null, { params });
};

export interface GetMechantRequestParams {
  name: string;
}

export type GetMechantRequestRes = {
  result: MechantItem[];
} & BaseRes;

export interface MechantItem {
  aProductType: string;
  aProductTypeName: string;
  aminAmot: string;
  annuTurn: string;
  approvalNote: string;
  compMarketing: string;
  contName: string;
  contTele: string;
  createTime: string;
  createUser: string;
  cusrDept: string;
  expandManager: string;
  ifEnd: string;
  isSupported: string;
  mAcountType: string;
  mCompanyAccout: string;
  mCompanyBank: string;
  mCompanyBankCode: string;
  mCompanyName: string;
  mCompanyOwn: string;
  mDeptType: string;
  mId: string;
  mMerchantAddress: string;
  mMerchantCode: string;
  mMerchantName: string;
  mMerchantNumb: string;
  mMerchantProduct: string;
  mMerchantRegNo: string;
  mMerchartAgent: string;
  mMerchartTel: string;
  mNickname: string;
  mNote: string;
  mOrgCode: string;
  mOrgName: string;
  mOwnCardNo: string;
  mPassword: string;
  mProcedure: string;
  mRegionCode: string;
  mSignDate: string;
  mStatus: string;
  mType: string;
  merchantType: string;
  modifyTime: string;
  modifyUser: string;
  pProductCode: string;
  pProductName: string;
  pProductPayType: string;
  pProductSchemeType: string;
  pProductSchemeVal: string;
  pid: string;
  propagandAdmissionaDate: string;
  propagandaDate: string;
  salesManager: string;
  stafSize: string;
  stoeSize: string;
  unitPrce: string;
}

export default Axios;

<template>
  <router-view />
</template>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #f2f1f5;
  // background-color: red;
  line-height: 1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
}
* {
  margin: 0;
  padding: 0;
}
div,
img {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<template>
  <div class="WxLogin">
    <img class="login_bg" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginbg.png" alt="" />
    <div class="content">
      <div class="login_content">
        <img class="logo" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginin_logobg.png" />
        <div class="login-view-content" v-if="loginCode == 'BE3014'">
          <div class="login-view-title">验证手机号码</div>
          <div class="login-view-text">手机号</div>
          <div class="login-view-input">
            <input v-model="loginParams.userTele" maxlength="11" placeholder="请输入手机号码" type="text" />
          </div>
          <div class="login-view-text">验证码</div>
          <div class="login-view-input">
            <input v-model="loginParams.code" placeholder="请输入验证码" type="text" />
            <div class="code" :class="{ no: codeNum != 60 }" @click="_getVerCode">{{ codeNum == 60 ? `获取验证码` : `${codeNum}s重发验证码` }}</div>
          </div>
        </div>

        <div class="login-view-content" v-else>
          <img
            class="winsucessful"
            v-if="loginCode == 'BE1000'"
            src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/icon_login_winsucessful.png"
          />
          <img
            class="loadingscan"
            v-if="loginCode != 'BE1000'"
            src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/loadingscan.gif"
          />
          <div v-if="loginCode != 'BE1000'" class="text">验证用户中......</div>
        </div>

        <div class="login_button" id="postMessage" @click="login" v-show="loginCode == 'BE3014'">登录</div>

        <img
          class="backbg"
          v-if="loginCode != 'BE1000'"
          src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/icon_login_backbg.png"
          @click="goback"
        />
      </div>

      <img class="loginin_bg" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginin_smallbg.png" />
    </div>
  </div>
</template>

<script>
import { pcWxAuthLogin, getVerCode, pcUserTeleAuthLogin } from '@/api/index';
import { createApp } from 'vue';
import { Toast, Loading, Dialog } from 'vant';
import 'vant/lib/index.css';

let _self;
const PHONE_RegEX = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
export default {
  data() {
    return {
      loginCode: '',
      params: {
        code: '',
        state: '',
      },
      loginParams: {
        userTele: '',
        code: '',
        nickName: '',
        userId: '',
        url: '/wxPc/pcUserTeleAuthLogin',
      },
      codeNum: 60,
      flag: false,
      envType: '',
    };
  },
  mounted() {
    _self = this;
    uni.getEnv(function (res) {
      for (const key in res) {
        _self.envType = key;
      }
    });

    const urlParams = (function () {
      const urlParams = new Object();
      const url = window.location.href;
      if (url.indexOf('?') > -1) {
        let str = url.split('?')[1];
        if (str.indexOf('#') > -1) str = str.split('#')[0];
        const strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
          urlParams[strs[i].split('=')[0].toLowerCase()] = strs[i].split('=')[1];
        }
      }
      return urlParams;
    })();

    this.params.code = urlParams.code;
    this.params.state = urlParams.state;

    this._pcWxAuthLogin();
  },
  methods: {
    goback() {
      this.$router.push({
        path: '/Login',
        replace: true,
      });
    },
    async _pcWxAuthLogin() {
      let params = JSON.parse(JSON.stringify(this.params));
      if (_self.envType === 'h5') {
        params.equtMentType = 'H5';
      } else if (_self.envType === 'plus') {
        params.equtMentType = 'PAD';
      }
      await pcWxAuthLogin(params).then((res) => {
        const data = res.data;
        switch (data.code) {
          case 'BE3014':
            this.loginCode = 'BE3014';
            this.loginParams.nickName = data.result.nickName;
            this.loginParams.userId = data.result.userId;
            break;
          case 'BE1000':
            _self.loginCode = 'BE1000';
            switch (_self.envType) {
              case 'h5':
                localStorage.setItem('loginToken', data.result.loginToken);
                localStorage.setItem('disturbCustUserCode', data.result.userCode);
                let location = window.location.href;
                location = location.replace('8081', '8080');
                const str = '/subpackages';
                const str2 = 'WxLogin';
                location = location.replace(str, '');
                location = location.replace(str2, 'pages/index/index');
                window.location.href = `${location}&logintoken=${data.result.loginToken}&usercode=${data.result.userCode}`;
                break;
              case 'plus':
                uni.postMessage({
                  data: data.result,
                });
                break;
            }
            break;
          case 'BE3016':
            Dialog.alert({ message: data.message, confirmButtonColor: '#008AFF' });
            break;
          case 'BE3017':
            Dialog.alert({ message: data.message, confirmButtonColor: '#008AFF' });
            break;
        }
      });
    },
    async _getVerCode() {
      if (!this.loginParams.userTele) {
        return Dialog.alert({ message: '请输入手机号码', confirmButtonColor: '#008AFF' });
      }
      if (this.flag) return false;
      this.flag = true;
      await getVerCode({ userTele: this.loginParams.userTele })
        .then((res) => {
          Toast('验证码发送成功');
          this.interval = setInterval(() => {
            this.codeNum--;
            if (this.codeNum === 0) {
              clearInterval(this.interval);
              this.codeNum = 60;
              this.flag = false;
            }
          }, 1000);
        })
        .catch((err) => {});
    },
    login() {
      switch (this.envType) {
        case 'h5':
          if (!this.loginParams.userTele) {
            return Dialog.alert({ message: '请输入手机号码', confirmButtonColor: '#008AFF' });
          }
          if (!PHONE_RegEX.test(this.loginParams.userTele)) {
            return Dialog.alert({ message: '请输入正确的手机号', confirmButtonColor: '#008AFF' });
          }
          if (!this.loginParams.code) {
            return Dialog.alert({ message: '请输入验证码', confirmButtonColor: '#008AFF' });
          }

          Toast.loading({
            duration: 0,
            forbidClick: true,
            message: '正在登录中...',
          });
          delete this.loginParams.url;
          let loginParams = JSON.parse(JSON.stringify(this.loginParams));
          loginParams.equtMentType = 'H5';
          pcUserTeleAuthLogin(loginParams).then((res) => {
            Toast.clear();
            if (res.data.code == 'BE1000') {
              localStorage.setItem('loginToken', res.data.result.loginToken);
              localStorage.setItem('disturbCustUserCode', res.data.result.userCode);
              localStorage.setItem('userCode', res.data.result.userCode);
              let location = window.location.href;
              const str = '/subpackages';
              const str2 = 'WxLogin';
              location = location.replace(str, '');
              location = location.replace(str2, 'pages/index/index');
              window.location.href = `${location}&logintoken=${res.data.result.loginToken}&usercode=${res.data.result.userCode}`;
              // uni.reLaunch({
              //   url: `/pages/index/index?logintoken=${res.data.result.loginToken}&usercode=${res.data.result.userCode}`,
              // });
            } else {
              Dialog.alert({ message: res.data.message, confirmButtonColor: '#008AFF' });
            }
          });
          break;
        case 'plus':
          uni.postMessage({
            data: this.loginParams,
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.WxLogin {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  .login_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .content {
    margin: 85px 52px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    .login_content {
      width: 335px;
      background-color: #fff;
      border-radius: 10px 0 0 10px;
      position: relative;
      .logo {
        // width: 97px;
        // height: 65px;
        width: 78px;
        height: 52px;
        margin: 55px auto 20px;
        display: block;
      }
      .loadingscan {
        width: 116px;
        height: 116px;
        display: block;
        margin: 130px auto 30px;
      }
      .winsucessful {
        width: 75px;
        height: 140px;
        display: block;
        margin: 130px auto 0;
      }
      .text {
        font-size: 18px;
        color: #323232;
        text-align: center;
      }
    }
    .backbg {
      position: absolute;
      left: 50%;
      bottom: 55px;
      transform: translateX(-50%);
      width: 61px;
      height: 17px;
      margin: e 10px;
    }
  }
  .loginin_bg {
    flex: 1;
    height: 100%;
  }
}
.login-view-content {
  .login-view-title {
    font-size: 18px;
    color: #323232;
    margin-left: 110px;
  }
  .login-view-text {
    font-size: 18px;
    color: #969696;
    margin: 50px 0 30px 60px;
  }
  .login-view-input {
    margin-left: 60px;
    width: 230px;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(200, 200, 200, 0.6);
    position: relative;
    input {
      border: none;
      flex: 1;
      color: #323232;
      font-size: 18px;
      text-align: left;
      padding: 0;
      margin: 0;
      &::placeholder {
        color: #e6e6e6;
      }
      &:focus {
        outline: none;
      }
    }
    .code {
      background: linear-gradient(90deg, #1286ff 17%, #28c1f3 100%);
      color: #fff;
      border-radius: 17.5px;
      height: 25px;
      line-height: 25px;
      padding: 0 6px;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      &.no {
        background: #fff;
        color: #969696;
      }
    }
  }
}
.login_button {
  margin: 40px 0 0 60px;
  width: 225px;
  background: linear-gradient(90deg, #1286ff 17%, #28c1f3 100%);
  box-shadow: 4px 7px 7px 0px rgba(0, 112, 255, 0.38);
  border-radius: 25px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  height: 50px;
  line-height: 50px;
}
</style>

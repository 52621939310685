import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
// import VConsole from 'vconsole'
// new VConsole()

createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .mount('#app');

<template>
  <div class="Login">
    <img class="login_bg" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginbg.png" alt="" />

    <div class="content">
      <div class="login_content">
        <img class="logo" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginin_logobg.png" />

        <div v-show="loginType === 0" id="login_container"></div>

        <img
          v-if="loginType === 0"
          class="login_userpageword"
          src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/icon_signin_userpageword.png"
          @click="bindLoginType(1)"
        />

        <div class="login-view-content" v-show="loginType === 1">
          <div class="login-view-text">登录账号</div>
          <div class="login-view-input">
            <input v-model="loginParams.userAcct" placeholder="请输入您的登录账号" type="text" />
          </div>
          <div class="login-view-text">登录密码</div>
          <div class="login-view-input">
            <input v-model="loginParams.userPass" placeholder="请输入您的登录密码" type="password" />
          </div>
        </div>

        <div v-show="loginType === 1" class="login_button" id="postMessage" @click="login">登录</div>

        <img
          v-show="loginType === 1"
          class="login_userpageword"
          style="width: 100px"
          src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/icon_signin_userwx.png"
          @click="bindLoginType(0)"
        />
      </div>
      <img class="loginin_bg" src="https://fdshare.oss-cn-shenzhen.aliyuncs.com/home/filedata/hunxiu-service-pad/img/images_loginin_smallbg.png" />
    </div>

    <div style="margin-bottom: 20px"></div>
  </div>
</template>

<script>
let _self;
import { loginWithAcctAndPass } from '@/api/index';
import { createApp } from 'vue';
import { Toast, Loading, Dialog } from 'vant';
import 'vant/lib/index.css';
// const app = createApp();
// app.use(Toast);

export default {
  data() {
    return {
      loginType: 0,
      loginParams: {
        userAcct: '',
        userPass: '',
        url: '/sysmUser/loginWithAcctAndPass',
      },
      envType: '',
    };
  },
  mounted() {
    _self = this;
    uni.getEnv(function (res) {
      for (const key in res) {
        _self.envType = key;
      }
    });

    this.initWxCode();
  },
  methods: {
    initWxCode() {
      const obj = new WxLogin({
        // self_redirect: true,
        id: 'login_container',
        appid: 'wx8847898e42371da6',
        scope: 'snsapi_login',
        // redirect_uri: 'http%3A%2F%2F192.168.0.77%3A8081%2F%23%2FWxLogin', //本地
        redirect_uri: 'https%3A%2F%2Fthltm.91afd.com%2Fpad%2Fsubpackages%2F%23%2FWxLogin', //测试
        // redirect_uri: 'https%3A%2F%2Fzadmin.xinnuoshijia.com%2Fpad%2Fsubpackages%2F%23%2FWxLogin', //仿真
        // redirect_uri: 'https%3A%2F%2Fhltm.91fd.com%2Fpad%2Fsubpackages%2F%23%2FWxLogin', //正式
        state: '',
        style: '',
        href: 'https://thltm.91afd.com/statictest/base.css',
      });
    },
    bindLoginType(loginType) {
      this.loginType = loginType;
      this.initWxCode();
    },
    login() {
      switch (this.envType) {
        case 'h5':
          if (!this.loginParams.userAcct) {
            return Dialog.alert({ message: '请输入您的登录账号', confirmButtonColor: '#008AFF' });
          }
          if (!this.loginParams.userPass) {
            return Dialog.alert({ message: '请输入您的登录密码', confirmButtonColor: '#008AFF' });
          }

          Toast.loading({
            duration: 0,
            forbidClick: true,
            message: '正在登录中...',
          });
          delete this.loginParams.url;
          const loginParams = JSON.parse(JSON.stringify(this.loginParams));
          loginParams.equtMentType = 'H5';
          loginWithAcctAndPass(loginParams).then((res) => {
            Toast.clear();
            if (res.data.code == 'BE1000') {
              localStorage.setItem('loginToken', res.data.result.loginToken);
              localStorage.setItem('disturbCustUserCode', res.data.result.userCode);
              let location = window.location.href;
              location = location.replace('8081', '8080');
              if (location.indexOf('Login') > -1) {
                const str = '/subpackages';
                const str2 = 'Login';
                location = location.replace(str, '');
                location = location.replace(str2, 'pages/index/index');
              } else {
                location += 'pages/index/index';
              }
              window.location.href = `${location}?logintoken=${res.data.result.loginToken}&usercode=${res.data.result.userCode}`;
            } else {
              Dialog.alert({ message: res.data.message, confirmButtonColor: '#008AFF' });
            }
          });
          break;
        case 'plus':
          uni.postMessage({
            data: this.loginParams,
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  .login_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .content {
    margin: 85px 52px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    .login_content {
      width: 335px;
      background-color: #fff;
      border-radius: 10px 0 0 10px;
      position: relative;
      .logo {
        // width: 97px;
        // height: 65px;
        width: 78px;
        height: 52px;
        margin: 55px auto 20px;
        display: block;
      }
      .login_userpageword {
        position: absolute;
        left: 50%;
        bottom: 55px;
        transform: translateX(-50%);
        width: 122px;
        height: 16.5px;
      }
      .login-view-content {
        .login-view-text {
          font-size: 18px;
          color: #969696;
          margin: 50px 0 30px 50px;
        }
        .login-view-input {
          margin: 0 auto;
          width: 230px;
          display: flex;
          align-items: center;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(200, 200, 200, 0.6);
          position: relative;
          input {
            border: none;
            flex: 1;
            color: #323232;
            font-size: 18px;
            text-align: left;
            padding: 0;
            margin: 0;
            &::placeholder {
              color: #e6e6e6;
            }
            &:focus {
              outline: none;
            }
          }
          .code {
            background: linear-gradient(90deg, #1286ff 17%, #28c1f3 100%);
            color: #fff;
            border-radius: 17.5px;
            height: 25px;
            line-height: 25px;
            padding: 0 6px;
            font-size: 14px;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
      .login_button {
        margin: 50px auto 0;
        width: 225px;
        background: linear-gradient(90deg, #1286ff 17%, #28c1f3 100%);
        box-shadow: 4px 7px 7px 0px rgba(0, 112, 255, 0.38);
        border-radius: 25px;
        font-size: 18px;
        text-align: center;
        color: #fff;
        height: 50px;
        line-height: 50px;
      }
      .text {
        position: absolute;
        left: 50%;
        bottom: 70px;
        transform: translateX(-50%);
        font-size: 16px;
        color: #008aff;
      }
    }
    .loginin_bg {
      flex: 1;
      height: 100%;
    }
  }
}
</style>

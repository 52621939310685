import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Index from '@/views/Index.vue';
import data from '@/views/data.vue';
import Login from '@/views/Login.vue';
import WxLogin from '@/views/WxLogin.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'data',
    component: data
  },
  {
    path: '/Login',
    name: 'login',
    component: Login,
  },
  {
    path: '/WxLogin',
    name: 'WxLogin',
    component: WxLogin,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
